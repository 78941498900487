/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel-autoheight";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const PropertyGallery = ({
  img,
  id,
  isRedirect,
  setActiveModalId,
  address,
}) => {
  const [onLoad, setOnLoad] = useState(false);

  return (
    <Link
      data-bs-toggle={!isRedirect && "modal"}
      data-bs-target={!isRedirect && "#propertyDetailQuickView"}
      target={isRedirect && "_blank"}
      className="property-img"
      to={isRedirect && `/property-details/${id}`}
      onClick={() => {
        setActiveModalId({ id, address });
      }}
    >
      <figure className={onLoad ? "" : "skeleton-loading"}>
        <img
          src={img.sizes?.thumb}
          loading="lazy"
          onLoad={() => {
            setOnLoad(true);
          }}
          alt=""
        />
      </figure>
    </Link>
  );
};

const PropertyItem = ({
  eachProperty,
  handleActiveMarker,
  isRedirect = true,
  setActiveModalId = () => {},
}) => {
  const { _id, address, bedrooms, fullBaths, sqFt, price, image } =
    eachProperty;

  const picturesList = Object.values(image);

  useEffect(() => {
    customScript();
  }, []);

  const customScript = () => {
    $(".property-item p").matchHeight({ property: "height" });
  };

  function formatToUSAPrice(number) {
    return number
      .toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
      .slice(0, -3);
  }

  const onShowProperty = () => {
    if (handleActiveMarker) {
      handleActiveMarker(_id);
    }
  };

  const onHideProperty = () => {
    if (handleActiveMarker) {
      handleActiveMarker(null);
    }
  };

  return (
    <>
      <li
        onMouseEnter={onShowProperty}
        onMouseLeave={onHideProperty}
        className="property-item"
      >
        <button type="button" className="my-list-btn fa fa-heart"></button>
        <div className="gallery-slider">
          <OwlCarousel
            className="owl-carousel property-item-slider"
            nav={true}
            dots={false}
            margin={10}
            lazyLoad={true}
            items={1}
          >
            {picturesList.map((eachImg, i) => (
              <PropertyGallery
                address={address}
                isRedirect={isRedirect}
                key={i}
                img={eachImg}
                id={_id}
                setActiveModalId={setActiveModalId}
              />
            ))}
          </OwlCarousel>
        </div>
        <p>
          <img src="../assets/images/location-img.png" alt="" /> {address}
        </p>
        <h6>
          <Link
            className="property-img"
            target={isRedirect && "_blank"}
            to={isRedirect && `/property-details/${_id}`}
          >
            Listing ID: {_id}
          </Link>
        </h6>

        <div className="property-item-bottom">
          <ul>
            <li>
              <i className="fa fa-bed" aria-hidden="true"></i> {bedrooms}
            </li>
            <li>
              <i className="fa fa-shower" aria-hidden="true"></i> {fullBaths}
            </li>
            <li>
              <i className="fa fa-crop" aria-hidden="true"></i> {sqFt} Sqft
            </li>
          </ul>
          <strong>{formatToUSAPrice(Number(price))}</strong>
        </div>
      </li>
    </>
  );
};

export default PropertyItem;
