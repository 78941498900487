import { Link, NavLink } from "react-router-dom";
import $ from "jquery";
import "./index.css";

function Header() {
  const customScript = () => {
    var forEach = function (t, o, r) {
      if ("[object Object]" === Object.prototype.toString.call(t))
        for (var c in t)
          Object.prototype.hasOwnProperty.call(t, c) && o.call(r, t[c], c, t);
      else for (var e = 0, l = t.length; l > e; e++) o.call(r, t[e], e, t);
    };
    var hamburgers = document.querySelectorAll(".mobile-toggle");
    if (hamburgers.length > 0) {
      forEach(hamburgers, function (hamburger) {
        $(hamburger).hasClass("is-active")
          ? $(hamburger).removeClass("is-active")
          : $(hamburger).addClass("is-active");
        $("#nav").hasClass("is-active")
          ? $("#nav").removeClass("is-active")
          : $("#nav").addClass("is-active");

        $("body").hasClass("overflow-y")
          ? $("body").removeClass("overflow-y")
          : $("body").addClass("overflow-y");
      });
    }
  };

  return (
    <header>
      <div className="container-fluid">
        <div className="header-block">
          <Link to={`/`} className="logo">
            <img src="../../assets/images/logo.png" alt="" />
          </Link>
          <nav id="nav">
            <ul className="navbar-nav">
              <li>
                <NavLink activeclassname="is-active" to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink activeclassname="is-active" to={`/buy`}>
                  Properties for Sale
                </NavLink>
              </li>
              <li>
                <NavLink activeclassname="is-active" to={`/sell`}>
                  About
                </NavLink>
              </li>
              <li>
                <NavLink activeclassname="is-active" to={`/about`}>
                  Locations
                </NavLink>
              </li>
              <li>
                <NavLink activeclassname="is-active" to={`/home-valuation`}>
                  Contact
                </NavLink>
              </li>
              <li>
                <NavLink activeclassname="is-active" to={`/market-snapshots`}>
                  How’s the Market?
                </NavLink>
              </li>
              <li>
                <NavLink activeclassname="is-active" to={`/blogs`}>
                  Property Management
                </NavLink>
              </li>
            </ul>
          </nav>
          <a href="tel:8007363119" className="btn large-btn">
            <i className="fa fa-phone"></i>(800) 736-3119
          </a>
          <button
            onClick={customScript}
            className="mobile-toggle mobile-toggle--squeeze"
            type="button"
          >
            <span className="mobile-toggle-box">
              <span className="mobile-toggle-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
